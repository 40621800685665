import React, { useState } from 'react';
import axios from 'axios';

const EmailTranscriptModal = ({ threadId, onClose }) => {
  const [email, setEmail] = useState('');

  const handleSendEmail = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/send-transcript`, {
        thread_id: threadId,
        email: email
      })
       .then(() => {
         alert('Email sent successfully!');
         onClose(); // Close modal on success
       })
       .catch((error) => {
         alert('Failed to send email.');
         console.error(error);
       });
  };

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>Send Transcript</h2>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <button onClick={handleSendEmail}>Send Email</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default EmailTranscriptModal;
