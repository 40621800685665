import React from 'react';
import './AssistantList.css'; // Make sure to import the CSS file

const AssistantList = ({ assistants, onSelectAssistant }) => (
  <div className="assistant-list">
    {assistants.map((assistant) => (
      <div
        key={assistant.id}
        className={`assistant-item ${!assistant.isEnabled ? 'disabled' : ''}`}
        onClick={() => assistant.isEnabled && onSelectAssistant(assistant.id)}
      >
        <h3>{assistant.name}</h3>
        <p>{assistant.description}</p>
      </div>
    ))}
  </div>
);

export default AssistantList;