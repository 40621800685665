import React, { Component } from 'react';
import ChatSession from './ChatSession';
import AssistantList from './AssistantList';
import ThreadList from './ThreadList';
import axios from 'axios';
import './App.css';

const assistants = [
  { id: 'asst_JU73McQp3R2CX53vZ0IpNDox', name: 'Retail v1', description: 'This is the retail v1 assitant.', isEnabled: true },
  { id: 'asst_e12lYLDWMIxdwVr2Qy3p4j0f', name: 'Parts v1', description: 'This is the part expert with sample data atm (currently not functional)', isEnabled: false }
];


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      threads: [], // Placeholder for threads data
      selectedThreadId: null,
      selectedThreadName: null,
      selectedAssistantId: null,
      isLoading: false,
    };
  }

  onCreateNewThread = () => {
    const { selectedAssistantId } = this.state;
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/start/${selectedAssistantId}`)
      .then(response => {
        this.setState({ selectedThreadId: response.data.thread_id });
      })
      .catch(error => console.error('Error starting chat thread:', error));
  };

  onSelectThread = (threadId, name) => {
    this.setState({ selectedThreadId: threadId, selectedThreadName: name });
  };

  onSelectAssistant = (assistantId) => {
    this.setState({ isLoading: true }, () => {
      // Start a new chat thread when the component mounts
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/threads/${assistantId}`)
        .then(response => {
          const threads = response.data.threads;
          this.setState({
            selectedAssistantId: assistantId,
            threads: threads, // Update your threads state with the fetched threads
            selectedThreadId: null, // Reset selected thread if any
            isLoading: false,
          });
        })
        .catch(error => {
          console.error('Error starting chat thread:', error);
          this.setState({ isLoading: false });
        });
    });
  };

  backToAssistantList = () => {
    this.setState({ selectedAssistantId: null, selectedThreadId: null });
  };

  backToThreadList = () => {
    this.setState({ selectedThreadId: null });
  };


  render() {
    const { threads, selectedThreadId, selectedThreadName, selectedAssistantId, isLoading } = this.state;
    
    if (isLoading) {
      return <div>Loading...</div>; // Simple loading indicator
    }

    if (selectedThreadId) {
      return (
        <div className="app-container">
          <ChatSession threadId={selectedThreadId} name={selectedThreadName} back={this.backToThreadList} assistantId={selectedAssistantId} />;
        </div>
      );
    } else if (selectedAssistantId) {
      return (
        <div className="app-container">
          <div>
            <button onClick={this.onCreateNewThread} className="create-thread-btn">Create New Thread</button>
            <ThreadList threads={threads} onSelectThread={this.onSelectThread} back={this.backToAssistantList} />;
          </div>
        </div>
      );
    } else {
      return <AssistantList assistants={assistants} onSelectAssistant={this.onSelectAssistant} />;
    }
  }
}


export default App;
