import React, { Component } from 'react';
import { marked } from 'marked'; // If you're rendering markdown

class ContentArea extends Component {
  renderContent = (content) => {
    if (content && content.startsWith("http")) {
      return <iframe src={content} title="iframe Content" width="100%" height="100%"></iframe>;
    }

    if (content) {
      const rawMarkup = marked.parse(content);
      return <div dangerouslySetInnerHTML={{ __html: rawMarkup }} />;
    }

    // If no content, display the placeholder image
    return '';
  }

  render() {
    const { content } = this.props;

    return (
      <div className="content-area">
        {this.renderContent(content)}
      </div>
    );
  }
}

export default ContentArea;
