import React from 'react';
import './ThreadList.css';

const ThreadList = ({ threads, onSelectThread, selectedThreadId, onCreateNewThread, back }) => {
  return (
    <div>
      <button onClick={back}>Back to Assistants</button>
      <ul className="thread-list">
        {threads.map((thread) => (
          <li
            key={thread.thread_id}
            className={`thread-item ${thread.thread_id === selectedThreadId ? 'selected' : ''}`}
            onClick={() => onSelectThread(thread.thread_id, thread.name)}
          >
            {thread.name || `Thread #${thread.thread_id}`}
          </li>
        ))}
      </ul>
    </div>
  );
};


export default ThreadList;
