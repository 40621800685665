import React, { Component } from 'react';
import ContentArea from './ContentArea';
import EmailTranscriptModal from './EmailTranscriptModal';
import axios from 'axios';
import { marked } from 'marked'; // Import marked

class ChatSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      currentMessage: '',
      waitingForBot: false,
      content: null,
      isModalOpen: false,
      chatTitle: this.props.name,
      isEditingTitle: false,
    };
  }

  componentDidMount() {
    this.fetchMessages();
  }

  componentDidUpdate(prevProps) {
    if (this.props.threadId !== prevProps.threadId) {
      this.fetchMessages();
    }
  }

  fetchMessages = () => {
    const { threadId } = this.props;
    const { chatTitle } = this.state;
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/threads/${threadId}/messages`)
      .then(response => {
        this.setState({ messages: response.data.messages.reverse(), chatTitle: chatTitle }); // Assuming the response contains an array of messages
      })
      .catch(error => console.error("Failed to fetch messages:", error));
  };

  handleSendMessage = () => {
    const { messages, currentMessage } = this.state;
    const { threadId, assistantId } = this.props;
    if (currentMessage.trim() !== '' && threadId) {
      // Add user's message to chat window
      this.setState({ 
        messages: [...messages, { sender: 'User', text: currentMessage }],
        currentMessage: '', // Clear the input box immediately after sending
        waitingForBot: true, // Set waiting for bot response
      });

      // Send the message to your API
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/chat`, {
        thread_id: threadId,
        message: currentMessage,
        assistant_id: assistantId
      })
      .then(response => {
        const aiMessage = response.data.response;
        const markdownContent = response.data.content
        // Add AI agent's response to chat window
        this.setState(prevState => ({
          messages: [...prevState.messages, { sender: 'AI', text: aiMessage }],
          content: markdownContent, // Assuming content state exists in ContentArea
          waitingForBot: false,
        }));
      })
      .catch(error => {
        console.error('Error sending message:', error);
        this.setState({ waitingForBot: false });
      });
    }
  };

  renderMarkdown(content) {
    const rawMarkup = marked.parse(content);
    return { __html: rawMarkup };
  }

  handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      this.handleSendMessage();
    }
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    console.log("Closing modal");
    this.setState({ isModalOpen: false });
  };

  startEditTitle = () => {
    this.setState({ isEditingTitle: true });
  };

  stopEditTitle = () => {
    this.setState({ isEditingTitle: false });
  };

  handleTitleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.stopEditTitle(); // Save the title and stop editing when Enter is pressed
    }
  };

  updateTitle = (e) => {
    const { threadId } = this.props;
    this.setState({ chatTitle: e.target.value });
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/name-thread`, {
        thread_id: threadId,
        name: e.target.value
      })
      .then(response => {
        console.log('successfully named thread');
      })
      .catch(error => {
        console.error('Error naming thread:', error);
      });
  };

  render() {
    const { messages, currentMessage, waitingForBot, content, isModalOpen, chatTitle, isEditingTitle } = this.state;
    const { threadId, back } = this.props;

    return (
      <div className="chat-container">
        <div className="chat-window">
          <div className="chat-title">
            {isEditingTitle ? (
              <input
                type="text"
                value={chatTitle}
                onBlur={this.stopEditTitle} // Stop editing on input blur
                onChange={this.updateTitle}
                onKeyPress={this.handleTitleKeyPress}
                autoFocus // Automatically focus the input
              />
            ) : (
              <h2 onClick={this.startEditTitle}>
                {chatTitle || `chat ${threadId}`}
              </h2>
            )}
            <button onClick={back}>Back to Threads</button>
          </div>
          <div className="messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`} dangerouslySetInnerHTML={this.renderMarkdown(message.text)}></div>
            ))}
            {waitingForBot && (
              <div className="message AI">
                <em>Bot is typing<span className="typing-placeholder"></span></em>
              </div>
            )}
          </div>
          <div className="message-input">
            <input
              type="text"
              value={currentMessage}
              onChange={(e) => this.setState({ currentMessage: e.target.value })}
              onKeyDown={this.handleEnterKey}
              placeholder="Type a message..."
            />
            <button onClick={this.handleSendMessage}>Send</button>
            <button onClick={this.openModal}>Email Transcript</button>
          </div>
        </div>
        {isModalOpen && <EmailTranscriptModal threadId={threadId} onClose={this.closeModal} />}
        <ContentArea content={content}/>
      </div>
    );
  };
}

export default ChatSession;
